import { Box, Divider, Grid, Hidden, Tab, Tabs } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { createMuiTheme, makeStyles, ThemeProvider, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import CloseIcon from '@material-ui/icons/Close'
import ForumIcon from '@material-ui/icons/Forum'
import { logoutApplication } from '../../../components/layout/Header'
import axios from 'axios'
import moment from 'moment'
import html2canvas from 'html2canvas'
import React, { Component, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import Sidebar from 'react-sidebar'
import {
  getAgentInfo,
  getCardEnableData,
  getClientDetails,
  getEncryptData,
  getEnrolledMemberData,
  getEnrollMemberInfoById,
  gethealthcard,
  getProgramInfo,
  getRxSimpleShareData,
  getSourceID
} from '../../ApiCall'
import Platform from 'react-platform-js'
import CommonLoader from '../../CommonLoader'
import MobCopyright from '../../MobileScreen/MobCopyright'
import MobileFooter from '../../MobileScreen/MobileFooter'
// import '../../MobileScreen/MobileFooter.css'
import ChatIcon from '../ChatBox/ChatIcon'
import CommonFooter from '../CommonFooter'
import Header from '../Header'
import customStyle from './stylesheet_UHS'
import i18n from './i18next'
import './ProgramInformation.css'
import styleJson from '../../styleJSON'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import SignScroll from './SignScroll'
import TextSignature from './TextSignature'
import Sample from './sampleTextField'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import CommonDropDwn from '../My Transaction/CommonDropDwn'
import { default as apiService, default as apiservice } from '../../../utils/apiservice'
import { getGateWayToken, startSession } from '../../../actions/homeActions'

const CustomeButton = withStyles(customStyle.viewNetwellBtn)(Button)
const NextButton = withStyles(customStyle.NextButtonNetwell)(Button)
const PurpleRadio = withStyles(customStyle.radioBtn)(props => <Radio color="default" {...props} />)
const CustomTextField = withStyles(customStyle.textCustomField)(TextField)

window.mobileAndTabletCheck = function() {
  let check = false
  ;(function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

class HipaaAuth extends Component {
  constructor(props) {
    super(props)

    this.state = {
      barRange: '0',
      barRangeYellow: '0',
      acsmTotal: '',
      acsmmet: '',
      acsmremain: '',
      nsamet: '',
      nsaremain: '',
      programname: '',
      programname2: '',
      showwithacsm: false,
      clientID: '',
      associationId: '',
      clientName: '',
      brokerId: '',
      subID: '',
      healthToolAddonActive: false,
      rxSimpleShareActive: false,
      addOnsObj: [],
      counterHealthTool: 0,
      counterRxSimpleShare: 0,
      enrolledDataMember: [],
      selectedTab: 'summary',
      netWellUser: process.env.REACT_APP_NETWELLUSER == 'true' ? true : false,
      styles: process.env.REACT_APP_NETWELLUSER == 'true' ? styleJson.NetWell : styleJson.UHF,
      ProgramInfoData: null,
      isForm: true,
      authName: '',
      openSignScroll: false,
      clearSign: false,
      signType: 'stylized',
      payflag: null,
      canvasChanged: false,
      canvasDone: false,
      imgUrl: '',
      signScrollImage: null,
      fullName: '',
      isValidFullName: true,
      submitText: null,
      tomorrow: new Date(),
      fromDate: null,
      openFrom: false,
      openTo: false,
      toDate: null,
      effectDate: null,
      openEffect: false,
      isDisableSubmit: true,
      secondRadio: null,
      thirdCheck: null,
      relation: '',
      relationList: ['PARENT', 'LEGAL GUARDIAN'],
      adultList: ['Gomez Addams', 'Morticia Addams'],
      memberEmail: '',
      loader: true,
      memberData: {},
      showForm: false,
      extendAuth: {}
    }
    this.child = React.createRef()
  }

  componentDidMount() {
    startSession().then(res => {
      console.log('did')
      this.setState({ loader: false })

      let url = window.location.href
      let state = {}
      if (url.split('HipaaAuthorization?').length > 1) {
        let queryString = url.split('HipaaAuthorization?')[1]
        let queryParams = new URLSearchParams(queryString)
        if (queryParams.has('state')) {
          let getState = decodeURI(queryParams.get('state'))
          console.log('state- - - ', JSON.parse(atob(getState)))
          this.setState({ memberData: JSON.parse(atob(getState)) }, () => {
            getEnrolledMemberData(this.state.memberData.primaryId?.toString()).then(res => {
              if (res && res.data) {
                console.log('resssssss--- ', res.data)
                let newRes = res.data.filter(res => res.memberId == this.state.memberData.memberId)
                console.log('newres = = ', newRes)
                if (newRes[0]?.status == 'TE') {
                  this.setState({ isHippaError: true, showForm: false, message: `Member status is terminated.` })
                } else {
                  axios
                    .get(process.env.REACT_APP_BASE_URL_VERSION + `memberportal/getHippaAuthById/${this.state.memberData.id}`)
                    .then(res => {
                      if (res.data.response.status == 'Completed') {
                        this.setState({
                          isHippaError: true,
                          showForm: false,
                          message: `Your HIPAA Authorization Form has already been submitted successfully on ${moment(
                            res.data.response.hippaPrivacyAuthorization.time
                          ).format('MMMM DD YYYY')}.`
                        })
                      } else this.setState({ showForm: true })
                      console.log('re-s-s-s-s, ', res.data.response)
                    })
                }
              }
            })
          })
        }
      }
    })
  }

  goBack = () => {
    // this.props.history.push('/')
  }
  changeSignType = (e, v) => {
    this.setState(
      {
        signType: v,
        fullName: v === 'stylized' ? '' : this.state.fullName
      },
      () => {
        this.enableSubmit()
      }
    )
  }
  nameChangeHandler = (val, isValid, parentObj) => {
    console.log('dev- - - - ', val, '-- ', parentObj)
    if (parentObj && parentObj.name == 'memberEmail') {
      this.setState(
        {
          memberEmail: val,
          isValidMemberEmail: true
        },
        () => {
          this.enableSubmit()
        }
      )
    } else if (parentObj && parentObj.name == 'fullName') {
      this.setState(
        {
          fullName: val,
          isValidFullName: true
        },
        () => {
          this.enableSubmit()
        }
      )
    }
    //  else {
    //   this.setState({
    //     fullName: "",
    //     isValidFullName: false,
    //   }, () => { this.enableSubmit() });
    // }
  }
  changeTextFieldHandler = (val, isValid, parentObj) => {
    console.log(val, ' - ', isValid, ' - ', parentObj)
    if (isValid) {
      this.state[parentObj.label] = val
    } else {
      this.state[parentObj.label] = ''
    }
    console.log(this.state.relation)
    this.enableSubmit()
    this.setState({
      refresh: true
    })
  }
  handleSignClear = () => {
    this.child.current.clearCanvas()
  }
  handleSignDone = () => {
    this.child.current.cropImageFromCanvas()
    // this.child.current.disableCanvas();

    this.setState({
      canvasDone: true,
      canvasChanged: false
    })
    this.nameChangeHandler(this.state.fullName, this.state.fullName !== '')
    const img = this.child.current.convertCanvasToImage()

    this.setState(
      {
        isValidFullName: true
      },
      () => {
        this.enableSubmit()
      }
    )

    return img.src
  }
  handleFromDate(event) {
    console.log(event)
    let formattedDate = ''
    if (event) {
      const selectedDate = new Date(event) // Convert the event to a JavaScript Date object
      formattedDate = `${selectedDate.getMonth() + 1}/${selectedDate.getDate()}/${selectedDate.getFullYear()}`
    }

    this.setState(
      {
        fromDate: formattedDate,
        fromDate1: event,
        toDate: null
      },
      () => {
        this.enableSubmit()
      }
    )
  }

  handleToDate(event) {
    console.log(event)
    let formattedDate = ''
    if (event) {
      const selectedDate = new Date(event) // Convert the event to a JavaScript Date object
      formattedDate = `${selectedDate.getMonth() + 1}/${selectedDate.getDate()}/${selectedDate.getFullYear()}`
    }
    this.setState(
      {
        toDate: formattedDate,
        toDate1: event
      },
      () => {
        this.enableSubmit()
      }
    )
  }
  handleEffectDate(event) {
    this.setState(
      {
        effectDate: moment.utc(event).format('YYYY-MM-DD'),
        effectDate1: event
      },
      () => {
        this.enableSubmit()
      }
    )
  }

  isDateValid(dateStr) {
    var date_regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/; ;
    return date_regex.test(dateStr)
  }

  checkCondition = () => {
    if(!this.state.authName){
      return true
    }

    if(!this.state.secondRadio){
      return true
    }else if( this.state.secondRadio === 'secondFrom' ){
      const fromDateValid = this.isDateValid(this.state.fromDate)
      const toDateValid = this.isDateValid(this.state.toDate)
      if(  (!fromDateValid ||  !toDateValid) || (new Date(this.state.toDate) < new Date(this.state.fromDate))){
        return true
      }
    }
    
    if(!this.state.thirdCheck){
      return true
    }else{
      if(this.state.thirdCheck !== 'thirdCheck'){
        if(!this.state.mental && !this.state.alcohol && !this.state.other){
          return true
        }
        if(this.state.other && !this.state.otherHealthRecord){
          return true
        }
      }
    }

    if(!this.state.fullName){
      return true
    }

    if(this.state.memberData.dependentNames && this.state.relation == ''){
      return true;
    }

    if(this.state.signType == 'scribble' && !this.state.canvasDone){
      return true
    }

    return false

  }

  enableSubmit = async() => {
    console.log(this.state.memberData.dependentNames != undefined, '-- ', this.state.relation == '', ' -fnam ')
    const submitState = await this.checkCondition()
    this.setState({
      isDisableSubmit: submitState
    });
    
  }

  uploadImage = async file => {
    this.setState({
      loaderShow: true
    })
    let formData = new FormData()
    formData.append('file', file, `${new Date().getTime()}.png`)

    await axios.post(process.env.REACT_APP_BASE_URL_VERSION + 'memberportal/uploadImage', formData).then(res => {
      this.setState({
        loaderShow: false,
        imgUrl: res.data.response
      })
      return res.data.response
    })
  }
  textSignatureToImage = () => {
    let node = document.getElementById('signTextContent')
    return html2canvas(node).then(function(canvas) {
      return canvas.toDataURL('image/png')
    })
  }
  dataURItoBlob(dataURI) {
    let byteString = atob(dataURI.split(',')[1])

    let mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0]

    let ab = new ArrayBuffer(byteString.length)
    let ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ab], { type: mimeString })
  }

  saveHipaaAuth = async () => {
    this.setState({ loader: true })
    this.state.extendAuth.mental = this.state.mental
    this.state.extendAuth.alcohol = this.state.alcohol
    this.state.extendAuth.other = this.state.otherHealthRecord
    this.state.extendAuth.fromdate = this.state.fromDate
    this.state.extendAuth.todate = this.state.toDate
    this.state.extendAuth.fullname = this.state.fullName
    const date = new Date()
    const dateAsString = date.toString()
    const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1]
    var matches = timezone.match(/\b(\w)/g)
    var abbreviations = matches.join('')
    let ipAddress = null

    // await axios.get('https://geolocation-db.com/json/').then(res => {
    //   console.log(res.data.IPv4)
    //   ipAddress = res.data.IPv4
    // })

    if (this.state.signType === 'stylized') {
      const img = await this.textSignatureToImage()
      await this.uploadImage(this.dataURItoBlob(img))
    }

    if (this.state.signType === 'scribble') {
      await this.uploadImage(this.dataURItoBlob(this.handleSignDone()))
    }

    let data = {
      name: this.state.memberData.memberName,
      memberId: this.state.memberData.memberId,
      authorizationName: this.state.authName,
      signature: this.state.imgUrl,
      ipAddress,
      browser: Platform.Browser,
      device: Platform.DeviceType || 'computer',
      os: Platform.OS,
      timestamp: abbreviations,
      relationship: this.state.relation,
      extendOfAuthorization: JSON.stringify(this.state.extendAuth),
      hippaAuthId: this.state.memberData.id
    }

    console.log('datatatata - -', data)
    axios.post(process.env.REACT_APP_BASE_URL_VERSION + 'memberportal/saveHippaPrivacyAuth', data).then(res => {
      if (res.data.code == 200) {
        axios
          .get(process.env.REACT_APP_BASE_URL_VERSION + `memberportal/HippaEsignPdf/${this.state.memberData.id}`)
          .then(res => {
            this.setState({
              isHippaSaved: true,
              message: 'Your HIPAA Authorization Form has been submitted successfully.',
              loader: false
            })
          })
          .catch(err => {
            this.setState({
              isHippaSaved: true,
              message: 'Your HIPAA Authorization Form has been submitted successfully.',
              loader: false
            })
          })
      } else {
        this.setState({
          isHippaError: true,
          message: 'Your HIPAA Authorization Form has already been submitted.',
          loader: false
        })
      }
    }).catch(err => {
      this.setState({
        loader: false
      })
    })
  }

  handleMessageModal = () => {
    // this.props.history.push('/login')
    // window.opener = null;
    // window.open("", "_self",'');
    this.setState({ isHippaSaved: false, isHippaError: false })
    window.open('', '_self', '')
    window.close()

    // var customWindow = window.open('', '_blank', '');
    // customWindow.close();
  }

  selectThirdCheck = event => {
    let extend = {}
    extend.mental = false
    extend.alcohol = false
    extend.other = ''
    if (event == 'thirdCheck') {
      this.setState({
        mental: false,
        alcohol: false,
        other: false,
        otherHealthRecord: ''
      })
      extend.authorizeAll = true
    } else extend.authorizeAll = false

    this.setState({ thirdCheck: event, extendAuth: { ...extend, ...this.state.extendAuth } }, () => {
      this.enableSubmit()
    })
    console.log('exxxxxx ', extend)
  }
  setValueOfSecondRadio(value) {
    let extend = {}
    if (value === 'secondOr') {
      this.setState({ fromDate: null, toDate: null })
      extend.allperiod = true
    } else {
      extend.allperiod = false
    }
    this.setState({ secondRadio: value, extendAuth: extend }, () => {
      this.enableSubmit()
    })
  }
  render() {
    console.log('extend', this.state.extend)
    return (
      <div className="progInfoMainWrapper">
        {this.state.loader ? <CommonLoader /> : null}
        <div>
          <div
            // className="commonWrap"
            style={{
              paddingTop: window.mobileAndTabletCheck() ? '40px' : '',
              backgroundColor: window.mobileAndTabletCheck() ? '#fff' : ''
            }}
          >
            <div className="progInfoSection" style={{ padding: ' 0 100px 0.5px' }}>
              <div style={{ paddingTop: '3pt', paddingLeft: '45pt', textIndent: '0pt', textAlign: 'center' }}>
                <img src={require(`../../Images/HomeIcon/${this.state.styles.headerLogo}`)} class={this.state.styles.headerIconCss} />
                <text className="logo-footer">Your Healthcare, Our Solution</text>
              </div>
              <div style={customStyle.s1}>
                <span>HIPAA </span>
                <span style={{ color: '#F8F9F9' }}>PRIVACY AUTHORIZATION FORM</span>
              </div>
              <div style={customStyle.s3} className="s3">
                Authorization for Use or Disclosure of Protected Health Information (Required by the Health Insurance Portability and
                Accountability Act, 45 C.F.R. Parts 160 and 164)
              </div>
              <div style={customStyle.s1}>
                <span>1. </span>
                <span style={{ color: '#F8F9F9' }}>AUTHORIZATION</span>
              </div>
              <div style={customStyle.s3} className="s3">
                I{' '}
                <CustomTextField
                  required={true}
                  name="name"
                  value={this.state.authName}
                  onChange={e =>
                    this.setState({ authName: e.target.value }, () => {
                      this.enableSubmit()
                    })
                  }
                  style={customStyle.textFieldWrp}
                  helperText={this.authName === '' || this.state.isValid ? this.state.errorText : ''}
                  error={this.state.isValid}
                  variant="filled"
                />{' '}
                authorize netWell to use and disclose the protected health information described below to netWell. (Required Field)
              </div>
              <div style={customStyle.s1}>
                <span>2. </span>
                <span style={{ color: '#F8F9F9' }}>EFFECTIVE PERIOD</span>
              </div>
              <div style={customStyle.s3}>
                <div>
                  <div>
                    This authorization for release of information covers the period of healthcare from:
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 12 }}>
                        <RadioGroup
                          row
                          aria-label="option"
                          name="row-radio-buttons-group"
                          value={this.state.secondRadio}
                          onChange={(event, value) => {
                            this.setValueOfSecondRadio(value)
                          }}
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <div style={{ display: 'flex' }}>
                            <FormControlLabel
                              style={{ marginRight: '1px' }}
                              className="lableStyle"
                              labelPlacement="start"
                              label="A."
                              value="secondFrom"
                              control={<PurpleRadio />}
                            />
                            <MuiPickersUtilsProvider style={{ width: '100px' }} utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                margin="none"
                                format="MM/dd/yyyy"
                                value={this.state.fromDate}
                                inputProps={{
                                  style: {
                                    fontSize: 15,
                                    backgroundColor: '#f8f8f8',
                                    color: '#787885',
                                    fontWeight: '400',
                                    fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                                    marginTop: '3px',
                                    paddingLeft: 5,
                                    textAlign: 'center'
                                  }
                                }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                onChange={event => this.handleFromDate(event)}
                                variant="filled"
                                open={false}
                                disabled={this.state.secondRadio !== 'secondFrom'}
                                // minDate={new Date(this.state.tomorrow)}
                                KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
                              />
                            </MuiPickersUtilsProvider>
                            <text style={{ margin: '10px' }}>to</text>
                            <MuiPickersUtilsProvider style={{ marginLeft: '10px' }} utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                margin="none"
                                format="MM/dd/yyyy"
                                value={this.state.toDate}
                                disabled={this.state.secondRadio !== 'secondFrom'}
                                inputProps={{
                                  style: {
                                    fontSize: 15,
                                    backgroundColor: '#f8f8f8',
                                    color: '#787885',
                                    fontWeight: '400',
                                    fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                                    marginTop: '3px',
                                    paddingLeft: 5,
                                    textAlign: 'center'
                                  }
                                }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                onChange={event => this.handleToDate(event)}
                                variant="filled"
                                open={false}
                                minDate={new Date(this.state.fromDate)}
                                KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                          <div>
                            <text className="displayOr1">OR</text>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'baseline' }}>
                            <FormControlLabel value="secondOr" labelPlacement="start" control={<PurpleRadio />} label="B." />
                            <div style={customStyle.s3}> All past, present, and future periods.</div>
                          </div>
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={customStyle.s1}>
                <span>3. </span>
                <span style={{ color: '#F8F9F9' }}>EXTENT OF AUTHORIZATION</span>
              </div>
              <div style={customStyle.s3}>
                <div>
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 12 }}>
                        <RadioGroup
                          row
                          aria-label="option"
                          name="row-radio-buttons-group"
                          value={this.state.thirdCheck}
                          onChange={(event, value) => this.selectThirdCheck(event.target.value)}
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <Grid style={{ display: 'flex', alignItems: 'baseline' }}>
                            <FormControlLabel
                              value="thirdCheck"
                              control={<PurpleRadio />}
                              label="A."
                              labelPlacement="start"
                              sx={{ fontSize: '15px' }}
                            />
                            <div style={customStyle.s3}>
                              I authorize the release of my complete health record (including records relating to mental healthcare,
                              communicable diseases, HIV or AIDS, and treatment of alcohol or drug abuse.
                            </div>
                          </Grid>

                          <text style={customStyle.displayOr}>OR</text>

                          <Grid style={{ display: 'flex', alignItems: 'baseline' }}>
                            <FormControlLabel
                              sx={{ fontSize: '15px' }}
                              value="thirdOr"
                              labelPlacement="start"
                              control={<PurpleRadio />}
                              label="B."
                            />
                            <div style={customStyle.s3}>
                              I authorize the release of my complete health record with the exception of the following information:
                            </div>
                          </Grid>
                          <div style={customStyle.checkBoxDiv}>
                            <div>
                              <Checkbox
                                key={Math.random()}
                                disabled={this.state.thirdCheck != 'thirdOr'}
                                checked={this.state.mental}
                                inputProps={{
                                  'aria-label': 'secondary checkbox'
                                }}
                                style={{ color: '#533278', marginLeft: -12 }}
                                onClick={() => {
                                  this.setState({ mental: !this.state.mental },()=>{
                                    this.enableSubmit()
                                  })
                                }}
                              />
                              <Grid
                                item
                                xs={12}
                                style={{
                                  marginLeft: '25px',
                                  fontSize: '15px',
                                  marginTop: '-36px',
                                  fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
                                }}
                              >
                                Mental health records and Communicable diseases (including HIV and AIDS)
                              </Grid>
                            </div>
                            <div>
                              <Checkbox
                                key={Math.random()}
                                disabled={this.state.thirdCheck != 'thirdOr'}
                                checked={this.state.alcohol}
                                inputProps={{
                                  'aria-label': 'secondary checkbox'
                                }}
                                style={{ color: '#533278', marginLeft: -12 }}
                                onClick={() => {
                                  this.setState({ alcohol: !this.state.alcohol },()=>{
                                    this.enableSubmit()
                                  })
                                }}
                              />
                              <Grid
                                item
                                xs={12}
                                style={{
                                  marginLeft: '25px',
                                  fontSize: '15px',
                                  marginTop: '-36px',
                                  fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
                                }}
                              >
                                Alcohol/drug abuse treatment
                              </Grid>
                            </div>

                            <div>
                              <Checkbox
                                key={Math.random()}
                                disabled={this.state.thirdCheck != 'thirdOr'}
                                checked={this.state.other}
                                inputProps={{
                                  'aria-label': 'secondary checkbox'
                                }}
                                style={{ color: '#533278', marginLeft: -12 }}
                                onClick={() => {
                                  this.setState({ other: !this.state.other },()=>{
                                    this.enableSubmit()
                                  })
                                }}
                              />
                              <Grid
                                item
                                xs={12}
                                style={{
                                  marginLeft: '25px',
                                  fontSize: '15px',
                                  marginTop: '-36px',
                                  fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
                                }}
                              >
                                Other (please specify): &nbsp;
                                <CustomTextField
                                  required={true}
                                  name="name"
                                  multiline
                                  maxRows={4}
                                  value={this.state.otherHealthRecord}
                                  onChange={e =>
                                    this.setState({ otherHealthRecord: e.target.value }, () => {
                                      this.enableSubmit()
                                    })
                                  }
                                  style={window.mobileAndTabletCheck() ? customStyle.textFieldWrpMob : customStyle.textFieldWrp}
                                  helperText={this.otherHealthRecord === '' || this.state.isValid ? this.state.errorText : ''}
                                  error={this.state.isValid}
                                  disabled={!this.state.other}
                                />
                              </Grid>
                            </div>
                          </div>
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={customStyle.s3}>
                <text style={customStyle.boldText}>4. </text>This medical information may be used by the person I authorize to receive this
                information for medical treatment or consultation, billing or share requests, or other purposes as I may direct.
              </div>
              <div style={customStyle.s3}>
                <text style={customStyle.boldText}>5. </text>I understand that my treatment, payment, enrollment, or eligibility for
                services will not be conditioned on whether I sign this authorization.
              </div>
              <div style={customStyle.s3}>
                <text style={customStyle.boldText}>6. </text>I understand that information used or disclosed pursuant to this authorization
                may be disclosed by the recipient and may no longer be protected by federal or state law.
              </div>
              <Grid item xs={12} sm={7} md={5} sx={{ width: '41%' }} style={{ margin: '25px 0px' }}>
                <Grid style={customStyle.authField}>
                  Authorization for {this.state.memberData.dependentNames || this.state.memberData.memberName}
                </Grid>
                <Sample
                  setChild={this.nameChangeHandler.bind(this)}
                  name={'FullName'}
                  isValidFullName={this.state.isValidFullName}
                  reqFlag={true}
                  label={'Authorize this form by typing in your full name'}
                  value={this.state.fullName}
                  disable={false}
                  style={customStyle.textField}
                  length={50}
                  fieldType={'holderName'}
                  errMsg={'Enter valid full name'}
                  helperMsg={''}
                  parentDetails={{ name: 'fullName' }}
                ></Sample>
                {this.state.memberData.dependentNames && (
                  <CommonDropDwn
                    setChild={this.changeTextFieldHandler.bind(this)}
                    name={'relation'}
                    label={'Relationship to Dependent'}
                    value={this.state.relation}
                    disable={false}
                    style={customStyle.dropDown}
                    fieldType={'dropDwn'}
                    helperMsg={''}
                    List={this.state.relationList}
                    parentDetails={{ label: 'relation' }}
                    required={true}
                  ></CommonDropDwn>
                )}
                <div style={{ marginTop: 30 }}>
                  <Typography variant="h6" component="h3" style={customStyle.questionTitleText}>
                    e-signature
                  </Typography>
                  <RadioGroup
                    row
                    aria-label="option"
                    name="row-radio-buttons-group"
                    value={this.state.signType}
                    onChange={(event, value) => {
                      this.changeSignType(event, value)
                      this.setState({
                        canvasChanged: false,
                        canvasDone: false
                      })
                    }}
                  >
                    <FormControlLabel value="stylized" control={<PurpleRadio />} label="Use stylized script" />
                    <FormControlLabel value="scribble" control={<PurpleRadio />} label="Let me draw" />
                  </RadioGroup>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'block'
                  }}
                >
                  {this.state.signType === 'stylized' ? (
                    <>
                      <div id="txtsign" style={{ width: '100%', height: '100%' }}>
                        <TextSignature id="textSignature" text={this.state.fullName} fontSize={50} />
                      </div>
                    </>
                  ) : (
                    <SignScroll
                      ref={this.child}
                      onCanvasChange={() =>
                        this.setState({
                          canvasChanged: true
                        })
                      }
                      onCanvasClear={() =>
                        this.setState({
                          canvasChanged: false
                        })
                      }
                    />
                  )}
                </div>
                {this.state.signType !== 'stylized' ? (
                  <div>
                    <CustomeButton
                      variant="contained"
                      style={customStyle.viewProgSum}
                      onClick={() => {
                        this.handleSignClear()
                        this.setState({ canvasChanged: false })
                      }}
                      disabled={this.state.canvasChanged ? false : true}
                    >
                      CLEAR
                    </CustomeButton>

                    <CustomeButton
                      variant="contained"
                      style={customStyle.viewProgSum}
                      onClick={() => this.handleSignDone()}
                      disabled={this.state.canvasChanged ? false : true}
                    >
                      DONE
                    </CustomeButton>
                  </div>
                ) : (
                  <></>
                )}
              </Grid>
              {/* <div style={customStyle.innerList}>
                <div style={{ marginTop: -40 }}></div>
              </div> */}
              {/* <Box sx={{ width: '100%' }}>
                <div style={{ backgroundColor: '#fff' }}>
                  <div className={'enrolledTableClass '} style={{ marginLeft: '5px', marginBottom: 30 }}>
                    <div className="enrolledTable">
                      <Paper className={'tableContainer'} style={customStyle.tableContainer} stickyHeader>
                        <TableContainer style={{ backgroundColor: '#fff', maxHeight: '52vh' }} stickyHeader>
                          {this.state.showForm && (
                            <div style={{ overflow: 'hidden' }}>
                              <div style={customStyle.mainPointDiv}>
                                <text style={customStyle.boldText}>1. Authorization</text>
                                <div style={customStyle.innerList}>
                                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    <text style={{ fontWeight: '350' }}>
                                      I authorize <text style={customStyle.boldText}>netWell</text>
                                      <text style={{ flexWrap: 'wrap', fontWeight: '350' }}>
                                        {' '}
                                        to use and disclose the protected health information described below.
                                      </text>
                                    </text>
                                  </div>
                                </div>
                              </div>

                              <div style={customStyle.mainPointDiv}>
                                <text style={customStyle.boldText}>2. Effective Period</text>
                              </div>

                              <div style={customStyle.mainPointDiv}>
                                <text style={customStyle.boldText}>3. Extent of Authorization</text>
                              </div>

                              <div style={customStyle.mainPointDiv}>
                                <text style={customStyle.boldText}>4. </text>
                                <text style={{ fontWeight: '350' }}>
                                  This medical information may be used by my health share provider and/or netWell representative, who I
                                  authorize to receive this information for medical treatment or consultation, billing or payments of
                                  medical request, or other purposes as I may direct.
                                </text>
                              </div>

                              <div style={customStyle.mainPointDiv}>
                                <text style={customStyle.boldText}>5. </text>
                                <text style={{ fontWeight: '350' }}>
                                  This authorization shall be in force for the entirety of my membership with netWell.
                                </text>
                              </div>

                              <div style={customStyle.mainPointDiv}>
                                <text style={customStyle.boldText}>6. </text>
                                <text style={{ fontWeight: '350' }}>
                                  I understand that my treatment, payment, enrollment, or eligibility will not be conditioned on whether I
                                  sign this authorization.
                                </text>
                              </div>
                            </div>
                          )}
                        </TableContainer>
                      </Paper>
                    </div>
                  </div>
                </div>
              </Box> */}
            </div>

            <div className="progInfoFooter" style={{ paddingTop: '0px' }}>
              <div className="row">
                <div className="col-md-12 dpFlex">
                  <div
                    className="footerText"
                    style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}
                  >
                    <div>
                      {this.state.showForm && (
                        <Button
                          onClick={() => this.saveHipaaAuth()}
                          onHide={() => this.handleMessageModal()}
                          type="submit"
                          variant="contained"
                          color=""
                          class={!this.state.isDisableSubmit ? 'login_button_netwell' : 'login_button_netwell_disable'}
                          disabled={this.state.isDisableSubmit}
                        >
                          SUBMIT
                        </Button>
                      )}
                    </div>
                    <div style={{ display: window.mobileAndTabletCheck() ? 'none' : '' }}>
                      <p>Need help?</p>
                      <p>Call our netWell Representative</p>
                      <p>on (866) NETWELL (638-9355).</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobileFooter">
            <div style={{}}>
              <ChatIcon
                shiftup={this.state.selectedTab === 'enrolledMembers' || this.state.selectedTab === 'addOns' ? true : false}
                changeProgram={this.state.selectedTab === 'summary' ? true : false}
                showAIChatIcon={true}
              />
              <div>
                <ChatIcon />
              </div>
              <div>
                <MobCopyright />
              </div>
            </div>
          </div>
          {/* <div className="fixed-bottom" style={{ visibility: window.mobileAndTabletCheck() ? '' : 'hidden' }}>
                        <MobileFooter />
                    </div> */}
        </div>

        <div className="webFooter" style={{ margin: '40px 0px 0px' }}>
          <CommonFooter />
        </div>

        <Modal size="small" show={this.state.isHippaSaved} onHide={() => this.handleMessageModal()} centered backdrop="static">
          <Modal.Header style={customStyle.modal_header}>
            <Modal.Title style={{ fontWeight: 'normal' }}>Message</Modal.Title>
            <IconButton aria-label="close" onClick={() => this.handleMessageModal()} style={{ marginTop: '-13px' }}>
              <CloseIcon />
            </IconButton>
          </Modal.Header>

          <Modal.Body>
            <b>{this.state.message}</b>
          </Modal.Body>

          <Modal.Footer>
            <NextButton onClick={() => this.handleMessageModal()}>OK</NextButton>
          </Modal.Footer>
        </Modal>
        <Modal size="small" show={this.state.isHippaError} onHide={() => this.handleMessageModal()} centered backdrop="static">
          <Modal.Header style={customStyle.modal_header}>
            <Modal.Title style={{ fontWeight: 'normal' }}>Message</Modal.Title>
            <IconButton aria-label="close" onClick={() => this.handleMessageModal()} style={{ marginTop: '-13px' }}>
              <CloseIcon />
            </IconButton>
          </Modal.Header>

          <Modal.Body>
            <b>{this.state.message}</b>
          </Modal.Body>

          <Modal.Footer>
            <NextButton onClick={() => this.handleMessageModal()}>OK</NextButton>
          </Modal.Footer>
        </Modal>

        {/* ===============================Error Modal====================================== */}
      </div>
    )
  }
}
export default withRouter(HipaaAuth)
